import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowLeft,
  faArrowRight,
  faBuilding as fasBuilding,
  faCaretDown,
  faCaretLeft,
  faCaretUp,
  faCheck,
  faCircle as fasCircle,
  faEdit as fasEdit,
  faEnvelope,
  faExclamationTriangle as fasExclamationTriangle,
  faFilePdf,
  faFilter as fasFilter,
  faKey,
  faLock,
  faSitemap as fasSitemap,
  faTimes as fasTimes,
  faTrashAlt as fasTrashAlt,
  faPlusCircle as fasPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faCircle,
  faFilter,
  faHorizontalRule,
  faSignIn,
  faTimes,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAnalytics,
  faBan,
  faBlockQuestion,
  faBuilding,
  faCalendarAlt,
  faCalendarCheck,
  faCheckCircle,
  faCheckDouble,
  faClipboardCheck,
  faClipboardListCheck,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faDoNotEnter,
  faEdit,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileDownload,
  faFileInvoiceDollar,
  faFileMedical,
  faFileMedicalAlt,
  faGripHorizontal,
  faHourglassHalf,
  faInfoCircle,
  faListAlt,
  faNotesMedical,
  faPaperPlane,
  faPlusCircle,
  faPlusSquare,
  faPrint,
  faSave,
  faScrubber,
  faSignOutAlt,
  faSitemap,
  faSync,
  faTabletAlt,
  faTachometerAlt,
  faTimesCircle,
  faTrashAlt,
  faUserCog,
  faUserEdit,
  faUserFriends,
  faUserMd,
  faUsersMedical,
} from '@fortawesome/pro-duotone-svg-icons';

export default class FontAwesomeLoader {
  constructor() {
    // Use the entire Free library
    library.add(far);

    // Explicit library additions
    library.add(
      faAnalytics,
      faArrowLeft,
      faArrowRight,
      faBan,
      faBlockQuestion,
      faBuilding,
      faCalendarAlt,
      faCalendarCheck,
      faCaretDown,
      faCaretLeft,
      faCaretUp,
      faCheck,
      faCheckCircle,
      faCheckDouble,
      faCircle,
      faClipboardCheck,
      faClipboardListCheck,
      faCloudDownloadAlt,
      faCloudUploadAlt,
      faDoNotEnter,
      faEdit,
      faEnvelope,
      faExclamationTriangle,
      faExternalLinkAlt,
      faFileDownload,
      faFileInvoiceDollar,
      faFileMedical,
      faFileMedicalAlt,
      faFilePdf,
      faFilter,
      faGoogle,
      faGripHorizontal,
      faHourglassHalf,
      faHorizontalRule,
      faInfoCircle,
      faKey,
      faListAlt,
      faLock,
      faNotesMedical,
      faPaperPlane,
      faPlus,
      faPlusCircle,
      faPlusSquare,
      faPrint,
      faSave,
      fasBuilding,
      fasCircle,
      faScrubber,
      fasEdit,
      fasExclamationTriangle,
      fasFilter,
      faSignIn,
      faSignOutAlt,
      faSitemap,
      fasSitemap,
      fasTimes,
      fasTrashAlt,
      faSync,
      faTabletAlt,
      faTachometerAlt,
      faTimes,
      faTimesCircle,
      faTrashAlt,
      faUserCog,
      faUserEdit,
      faUserFriends,
      faUserMd,
      faUsersMedical,
      fasPlusCircle,
    );

    // Replace any existing <i> tags with <svg> and set up a MutationObserver to continue doing this as the DOM changes
    dom.watch();
  }
}
