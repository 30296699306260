import { cloneDeep } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import {
  CLEAR_ORGANIZATION_EDIT_FORM_ERROR,
  CREATE_ORGANIZATION_FAILURE,
  CREATE_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_REQUEST,
  FETCH_ORGANIZATIONS_SUCCESS,
  RESET_ORGANIZATION_CREATE_FORM,
  RESET_ORGANIZATION_EDIT_FORM,
  UPDATE_ORGANIZATION_CREATE_FORM,
  UPDATE_ORGANIZATION_EDIT_FORM,
  UPDATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_SUCCESS,
} from '../types/organizationsTypes';

const schema = {
  type: 'object',
  properties: {
    createForm: {
      type: 'object',
      properties: {
        error: {
          type: ['string', 'null'],
          optional: true,
        },
        values: {
          type: 'object',
          properties: {
            city: { type: 'string' },
            name: { type: 'string' },
            state: { type: 'string', optional: true },
            streetAddress: { type: 'string' },
            telehealthURL: { type: ['string', 'null'], optional: true },
            zip: { type: 'string' },
            hideRevWidget: { type: 'boolean' },
          },
        },
      },
    },
    data: {
      type: ['object', 'null'],
      optional: true,
    },
    editForm: {
      type: 'object',
      properties: {
        error: {
          type: ['string', 'null'],
          optional: true,
        },
        values: {
          type: 'object',
          properties: {
            city: { type: 'string' },
            id: { type: 'string' },
            isActive: { type: 'boolean' },
            name: { type: 'string' },
            state: { type: 'string', optional: true },
            streetAddress: { type: 'string' },
            ssnEligibilityActive: { type: ['boolean', 'null'], optional: true },
            telehealthURL: { type: ['string', 'null'], optional: true },
            zip: { type: 'string' },
            hideRevWidget: { type: ['boolean', 'null'], optional: true },
            allowEligibilityHraBypass: { type: ['boolean', 'null'], optional: true },
            enabledAddOnBillingCodes: { type: ['array', 'null'], optional: true },
          },
        },
      },
    },
    error: { type: ['string', 'null'], optional: true },
    loading: { type: 'boolean' },
    scopeFilter: {
      type: ['object'],
      optional: true,
    },
  },
};

const initialState = {
  createForm: {
    error: null,
    values: {
      city: '',
      name: '',
      state: '',
      streetAddress: '',
      telehealthURL: '',
      zip: '',
      hideRevWidget: false,
    },
  },
  data: null,
  editForm: {
    error: null,
    values: {
      city: '',
      id: '',
      isActive: true,
      name: '',
      state: '',
      streetAddress: '',
      ssnEligibilityActive: false,
      telehealthURL: '',
      zip: '',
      hideRevWidget: false,
      allowEligibilityHraBypass: false,
      enabledAddOnBillingCodes: null,
    },
  },
  error: null,
  loading: false,
  scopeFilter: {},
};

const organizationsReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case CLEAR_ORGANIZATION_EDIT_FORM_ERROR: {
      return { ...state, editForm: { ...state.editForm, error: null } };
    }

    case CREATE_ORGANIZATION_FAILURE: {
      const newCreateForm = state.createForm;
      newCreateForm.error = action.error;

      return { ...state, loading: false, createForm: newCreateForm };
    }
    case CREATE_ORGANIZATION_SUCCESS: {
      const newOrgs = state.data;
      newOrgs[action.payload.id] = action.payload;

      return { ...state, data: { ...state.data, ...newOrgs } };
    }

    case FETCH_ORGANIZATIONS_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }
    case FETCH_ORGANIZATIONS_REQUEST: {
      return { ...state, loading: true };
    }
    case FETCH_ORGANIZATIONS_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }

    case UPDATE_ORGANIZATION_FAILURE: {
      const newEditForm = state.editForm;
      newEditForm.error = action.error;

      return { ...state, loading: false, editForm: newEditForm };
    }
    case UPDATE_ORGANIZATION_SUCCESS: {
      const newOrgs = state.data;
      newOrgs[action.payload.id] = { ...newOrgs[action.payload.id], ...action.payload };

      return { ...state, data: { ...state.data, ...newOrgs } };
    }

    case UPDATE_ORGANIZATION_CREATE_FORM: {
      return { ...state, loading: false, createForm: action.payload, error: null };
    }

    case UPDATE_ORGANIZATION_EDIT_FORM: {
      return { ...state, loading: false, editForm: action.payload, error: null };
    }

    case RESET_ORGANIZATION_CREATE_FORM: {
      return { ...state, loading: false, createForm: initialState.createForm };
    }

    case RESET_ORGANIZATION_EDIT_FORM: {
      return { ...state, loading: false, editForm: initialState.editForm };
    }

    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default organizationsReducer;
